<template>
  <div v-if="page">
    <h2>{{ $t("my-packages-title") }}</h2>
    <div v-if="!!page.doc.content" class="row mb-3">
      <page-title :subheading="page.doc.content"></page-title>
    </div>
    <packages-table class="mb-3" table-title="ACTIVE LOYALTY PROGRAM" :packages="page.viewer.packages
      .filter((p) => p.isPackageActive)
      .map((p) => this.transformDataActive(p))
      " :header="activeHeaders" :locked-rate="page.viewer.depositAddress" :globalStats="page.globalStats" />
    <packages-sell-table class="my-3" table-title="ACTIVE DIVIDEND PROGRAM" :packages="page.viewer.packages
      .filter((p) => !p.isPackageActive && (p.sellAt) && (p.sellBackStatus < 2 || p.sellBackStatus === 9))
      .map((p) => this.transformDataExpired(p))
      " :header="expiredHeaders" :globalStats="page.globalStats" />

    <packages-history-table class="my-3" table-title="HISTORY" :packages="page.viewer.packages
      .filter((p) => !p.isPackageActive && (!(p.sellAt) || (p.sellBackStatus > 1 && p.sellBackStatus !== 9)))
      .map((p) => this.transformDataExpired(p))
      " :header="historyHeaders" />
    <!-- <swap-table class="mb-3" :table-title="$t('swap-eligible-packages')" :packages="page.viewer.packagesForSwap.map(p => {return this.transformDataSwap(p);})" :header="swapHeaders" :locked-rate="page.viewer.depositAddress"/> -->
  </div>
</template>

<script>
import gql from "graphql-tag";
import PackagesTable from "@/components/Packages/PackagesTable";
import PackagesSellTable from "@/components/Packages/PackagesSellTable";
import PackagesHistoryTable from "@/components/Packages/PackagesHistoryTable";
import * as moment from "moment";
import PageTitle from "@/components/common/PageTitle";
// import SwapTable from "@/components/Packages/SwapTable";

export default {
  name: "Packages",
  components: {
    PageTitle,
    PackagesTable,
    PackagesSellTable,
    PackagesHistoryTable
    // SwapTable,
  },
  data() {
    return {
      heading: this.$i18n.t("Packages"),
      subheading: "My Hotelium Packages",
      icon: "pe-7s-user icon-gradient bg-strong-bliss",
      page: null,
      activeHeaders: [
        {
          value: "id",
          text: "ID",
          sortable: false,
        },
        {
          value: "id",
          text: "Date of activation",
          sortable: false,
          class: "text-center",
        },
        {
          value: "expires",
          text: "Loyalty expires on",
          sortable: false,
        },
        { value: "type", text: this.$i18n.t("type"), sortable: false },
        { value: "filled", text: this.$i18n.t("filled"), sortable: false },
        { value: "value", text: this.$i18n.t("value"), sortable: false },
        { value: "buttons", text: "", sortable: false },
        { value: "buttons", text: "", sortable: false },
      ],
      expiredHeaders: [
        {
          value: "id",
          text: "ID",
          sortable: false
        },
        {
          value: "id",
          text: "Starting date",
          sortable: false,
          class: "text-center",
        },
        {
          value: "expires",
          text: "Ending date",
          sortable: false,
        },
        { value: "type", text: this.$i18n.t("type"), sortable: false },
        { value: "value", text: this.$i18n.t("value"), sortable: false },
        { value: "buttons", text: "", sortable: false },
      ],
      historyHeaders: [
        { value: "username", text: "ID", sortable: false },
        { value: "packageId", text: "Duration of dividend program", sortable: false, class: "text-center" },
        { value: "packageId", text: "Sold / Convert date", sortable: false, class: "text-center" },
        { value: "type", text: this.$i18n.t("type"), sortable: false },
        { value: "fresh", text: this.$i18n.t("value"), sortable: false },
        { value: "value", text: "Status", sortable: false },
      ],

      // swapHeaders: [
      //   {
      //     value: "expires",
      //     text: this.$i18n.t("loyalty-expires"),
      //     sortable: false,
      //   },
      //   { value: "sellEligible", text: "Sell Eligible", sortable: false },
      //   { value: "type", text: this.$i18n.t("type"), sortable: false },
      //   { value: "filledSwap", text: "Filled Swap", sortable: false },
      //   { value: "valuePackage", text: "Value Package", sortable: false },
      //   { value: "extraSharesA", text: "Extra Shares HTL A", sortable: false },

      //   { value: "swappedToBDate", text: "Swapped to B Date", sortable: false },
      //   {
      //     value: "swappedToBValue",
      //     text: "Swapped Value HTL B",
      //     sortable: false,
      //   },

      //   { value: "sellDate", text: "Sold Date", sortable: false },
      //   { value: "soldValueHTL", text: "Sold Value HTL", sortable: false },

      //   {
      //     value: "actions",
      //     text: "Actions",
      //     sortable: false,
      //     class: "text-end",
      //   },
      // ],
    };
  },
  apollo: {
    page: {
      query: gql`
        query page {
          doc(id: "packages") {
            content
            title
          }
          viewer {
            id
            packages {
              id
              isPackageActive
              expiresAt
              sellAt
              sellBackStatus
              sellBackDate
              upgradedAt
              packageName
              packageType
              totalValueInTokens
              valueInFiat
              noCommission
              allowedUpgrades {
                id
                name
                price
                expiryDays
                hash
              }
            }
            packagesForSwap {
              id
              isPackageActive
              expiresAt
              upgradedAt
              packageName
              totalValueInTokens
              valueInFiat
              noCommission
              allowedUpgrades {
                id
                name
                price
                expiryDays
                hash
              }
              sharesAFromLoyalty
              dateSellEligible
              paidValueInFiat
              packageType
              sold
              swappedToSharesB
            }
            totals {
              tokens
            }
            depositAddress {
              id
              address
              currency
              expiresAt
              lockedRate
            }
          }
          lockedRate {
            expiresAt
            rate
            rateCrypto
            token
            pack
            currency
            htlRate
            upgrade
            price
          }
          viewerIsAdmin
          globalStats{
            held
            shares
            sharesA
            packageActive
            hotelBudget
          }
        }
      `,
      update: (data) => data,
      result({ data }) {
        if (data) {
          this.$store.commit("user/updateAvailable", {
            tokens: data.viewer.totals.tokens,
          });
          if (data.viewerIsAdmin) {
            this.$store.commit("user/setAdmin");
          }
        }
      },
    },
  },
  methods: {
    transformDataActive(p) {
      var now = new Date();
      var fullDiff = moment(p.expiresAt).diff(moment(p.id));
      var diff = moment(now).diff(moment(p.id));
      return {
        diff: Math.round((100 * diff) / fullDiff),
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        button: true,
        allowedUpgrades: p.allowedUpgrades,
        ...p,
      };
    },
    transformDataSwap(p) {
      var result = {};
      var now = new Date();
      {
        let fullDiff = moment(p.dateSellEligible).diff(moment(p.id));
        let diff = moment(now).diff(moment(p.id));
        let percentFilled = Math.min(Math.round((100 * diff) / fullDiff), 100);

        Object.assign(p, {
          diff: percentFilled,
          color: "danger",
          buttonSell: diff >= fullDiff,
          buttonSwap: true,
          // allowedUpgradesSwap: p.allowedUpgrades
        });
      }

      return p;
    },
    transformDataExpired(p) {
      return {
        color:
          p.valueInFiat[0] < 19000000
            ? "primary"
            : p.valueInFiat[0] < 900000000
              ? "success"
              : "dark",
        ...p,
      };
    },
  },
};
</script>

<style scoped></style>